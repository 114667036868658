<template>
    <div>
      <div class="pr-2">
        <v-breadcrumbs :items="breadcrumbs">
          <template v-slot:divider>
            <v-icon>chevron_right</v-icon>
          </template>
        </v-breadcrumbs>
      </div>
      <div v-if="$router.currentRoute.name === 'promotions'" class="promotions">
        <h1 class="text-xs-center">Promotions</h1>
        <div class="layout row justify-space-between align-center pa-2">
          <div>
            <v-btn :to="{ name: 'promotions-adhocpromotion' }" flat class="d-inline-block"><v-icon>fal fa-hand-holding-usd</v-icon> <span class="ml-2" style="text-decoration: underline">Send Promotional Credit</span></v-btn>
          </div>
          <div>
            <v-btn color="primary" :to="{ name: 'promotions-promotion', params: { promotionId: '0' } }" :disabled="apiInProgress"><v-icon left>fal fa-plus</v-icon> Create Promotion</v-btn>
          </div>
        </div>
        <v-layout row wrap>
          <v-flex md5 sm4 pa-2>
            <form @submit.prevent="onKeywordsSearch">
              <v-text-field
                  solo
                  clearable
                  v-model="searchKeywords"
                  append-icon="fal fa-search"
                  :rules="[v => !v || v.length >= 2 || 'Min 2 characters']"
                  :disabled="apiInProgress"
                  type="search"
                  placeholder="Search by custom name, script name, amount"
                  @click:append="onKeywordsSearch"
              />
            </form>
          </v-flex>
          <v-flex md2 sm2 pa-2>
            <div class="layout row justify-start align-center">
              <div class="mr-2">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">fal fa-info-circle</v-icon>
                  </template>
                  <span>{{selectedScript ? selectedScript.meta.description : 'Select a script for more info.'}}</span>
                </v-tooltip>
              </div>
              <v-select
                style="width: 80%"
                class="v-select--fixed-width"
                return-object
                clearable
                :items="promotionScripts"
                label="Scripts"
                v-model="selectedScript"
              />
            </div>
          </v-flex>
          <v-flex md4 sm5>
            <v-layout>
              <v-flex xs6 pa-2>
                <v-menu
                    v-model="dateFromMenu"
                    :close-on-content-click="false"
                    full-width
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                        prepend-icon="fal fa-calendar"
                        :value="computedDateFromFormatted"
                        @click:clear="dateFrom = null"
                        clearable
                        label="From"
                        readonly
                        v-on="on"
                    />
                  </template>
                  <v-date-picker
                      no-title
                      :max="computedDateFromMax"
                      v-model="dateFrom"
                      @change="dateFromMenu = false"
                  />
                </v-menu>
              </v-flex>
              <v-flex xs6 pa-2>
                <v-menu
                    v-model="dateToMenu"
                    :close-on-content-click="false"
                    full-width
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                        prepend-icon="fal fa-calendar"
                        :value="computedDateToFormatted"
                        @click:clear="dateTo = null"
                        clearable
                        label="To"
                        readonly
                        v-on="on"
                    />
                  </template>
                  <v-date-picker
                      no-title
                      :min="computedDateToMin"
                      v-model="dateTo"
                      @change="dateToMenu = false"
                  />
                </v-menu>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex md1 sm1 pa-2>
            <v-btn small fab @click="onKeywordsSearch"><v-icon small>fal fa-filter</v-icon></v-btn>
          </v-flex>
        </v-layout>
        <v-tabs icons-and-text @change="onTabChanged">
          <v-tabs-slider color="primary" />
          <v-tab ripple href="#promotions">
            Promotions {{selectedTab !== 'promotions' ? `(${totalPromotions})` : ''}}
            <v-icon>fal fa-gifts</v-icon>
          </v-tab>
          <v-tab ripple href="#archived-promotions">
            Archived Promotions {{selectedTab !== 'archived-promotions' ? `(${totalArchivedPromotions})` : '&nbsp;&nbsp;'}}
            <div>
              <div class="layout row align-center justify-start">
                <v-icon style="height: 24px">fal fa-gifts</v-icon>
                <v-icon class="ml-2" style="height: 24px">fal fa-times</v-icon>
              </div>
            </div>
          </v-tab>
          <v-tabs-items v-model="selectedTab">
            <v-tab-item value="promotions">
              <v-data-table
                  v-if="selectedTab === 'promotions'"
                  :headers="headers"
                  :items="promotions"
                  :pagination.sync="pagination"
                  :total-items="totalPromotions"
                  :loading="apiInProgress"
                  :rows-per-page-items="[10, 25, 50]"
                  @update:pagination="onPagination"
                  class="elevation-1"
                  must-sort
              >
                <template v-slot:items="props">
                  <tr :class="'tr--pointer'" @click="loadPromotion(props.item)">
                    <td :title="props.item.customName">
                      <v-text-field
                          class="text-field--flat"
                          v-model="props.item.customName"
                          hide-details
                      />
                      <div v-show="props.item.isExpired">
                        <v-tooltip v-if="props.item.expiredTooltip" top>
                          <template v-slot:activator="{ on }">
                            <v-icon small v-on="on">fa fa-info-circle</v-icon>
                          </template>
                          <span>{{props.item.expiredTooltip}}</span>
                        </v-tooltip> &nbsp;
                        <small class="error--text">Invalid</small>
                      </div>
                    </td>
                    <td :title="props.item.fileName">
                      <v-text-field
                          class="text-field--flat"
                          v-model="props.item.fileName"
                          hide-details
                      />
                      <div v-show="props.item.metadata">
                        <v-icon small>fa fa-dolly</v-icon> &nbsp;&nbsp;
                        <small class="success--text">Published</small>
                      </div>
                    </td>
                    <td><span class="success--text">{{ props.item.budgetLabel }}</span></td>
                    <td>
                      <span class="error--text">{{ props.item.budgetSpentLabel }}</span>
                      <div><v-chip :color="props.item.thresholdWarning ? 'warning' : ''" v-if="props.item.budgetThresholdLabel" small><small>threshold: {{ props.item.budgetThresholdLabel }}</small></v-chip></div>
                    </td>
                    <td><small>{{ props.item.dateStartLabel }}</small></td>
                    <td><small>{{ props.item.dateEndLabel }}</small></td>
                    <td><small>{{ props.item.dateUpdatedLabel }}</small></td>
                    <td class="justify-center layout px-0">
                      <v-btn flat icon title="Archive" :disabled="props.item.isArchiveInProgress" :loading="props.item.isArchiveInProgress"  @click.stop="archivePromotion(props.item)">
                        <v-icon small>
                          fas fa-archive
                        </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-tab-item>
            <v-tab-item value="archived-promotions">
              <v-data-table
                  v-if="selectedTab === 'archived-promotions'"
                  :headers="archivedHeaders"
                  :items="archivedPromotions"
                  :pagination.sync="archivedPagination"
                  :total-items="totalArchivedPromotions"
                  :loading="apiInProgress"
                  :rows-per-page-items="[10, 25, 50]"
                  @update:pagination="onArchivedPagination"
                  class="elevation-1"
                  must-sort
              >
                <template v-slot:items="props">
                  <tr :class="'tr--pointer'" @click="loadPromotion(props.item)">
                    <td :title="props.item.customName">
                      <v-text-field
                          class="text-field--flat"
                          v-model="props.item.customName"
                          hide-details
                      />
                      <div v-show="props.item.isExpired">
                        <v-tooltip v-if="props.item.expiredTooltip" top>
                          <template v-slot:activator="{ on }">
                            <v-icon small v-on="on">fa fa-info-circle</v-icon>
                          </template>
                          <span>{{props.item.expiredTooltip}}</span>
                        </v-tooltip> &nbsp;
                        <small class="error--text">Invalid</small>
                      </div>
                    </td>
                    <td :title="props.item.fileName">
                      <v-text-field
                          class="text-field--flat"
                          v-model="props.item.fileName"
                          hide-details
                      />
                    </td>
                    <td><span class="success--text">{{ props.item.budgetLabel }}</span></td>
                    <td>
                      <span class="error--text">{{ props.item.budgetSpentLabel }}</span>
                      <div><v-chip :color="props.item.thresholdWarning ? 'warning' : ''" v-if="props.item.budgetThresholdLabel" small><small>threshold: {{ props.item.budgetThresholdLabel }}</small></v-chip></div>
                    </td>
                    <td><small>{{ props.item.dateStartLabel }}</small></td>
                    <td><small>{{ props.item.dateEndLabel }}</small></td>
                    <td><small>{{ props.item.dateCreatedLabel }}</small></td>
                    <td><small>{{ props.item.dateArchivedLabel }}</small></td>
                    <td class="justify-center layout px-0">
                      <v-btn flat icon title="Un-archive" :disabled="props.item.isUnArchiveInProgress" :loading="props.item.isUnArchiveInProgress"  @click.stop="unArchivePromotion(props.item)">
                        <v-icon small>
                          fas fa-box-open
                        </v-icon>
                      </v-btn> &nbsp;
                      <v-btn flat icon title="Permanently delete" :disabled="props.item.isUnArchiveInProgress" :loading="props.item.isUnArchiveInProgress"  @click.stop="deletePromotion(props.item)">
                        <v-icon small>
                          fas fa-trash-alt
                        </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </div>
      <router-view :signed-in-user="signedInUser" />
    </div>
</template>

<script>
import src from './src'
export default src
</script>
