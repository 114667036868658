import { apiRequests } from '../utilities/axios-factory'
import store from '../store'

function getPromotion ({ id, filter }) {
  return apiRequests.get('/v1/promotion-setting/' + id + '/', {
    params: { filter },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function findPromotions (filter) {
  return apiRequests.get('/v1/promotion-setting/join/', {
    params: { filter },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function findTotalPromotions ({ where, join }) {
  return apiRequests.get('/v1/promotion-setting/count/join/', {
    params: { where, join },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function findValidPromotions ({ filter, timestamp }) {
  return apiRequests.get('/v1/promotion-setting/valid/', {
    params: { filter, timestamp },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function findTotalValidPromotions ({ where, join }) {
  return apiRequests.get('/v1/promotion-setting/valid/count/', {
    params: { where, join },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function getPromotionScripts () {
  return apiRequests.get('/v1/promotion-setting/scripts/', {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function archivePromotion ({ id, permanently }) {
  return apiRequests.delete('/v1/promotion-setting/' + id + '/' + (permanently ? '?permanently=true' : ''), {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function unArchivePromotion ({ id }) {
  return apiRequests.put('/v1/promotion-setting/' + id + '/unarchived/', null, {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function publishPromotion ({ id }) {
  return apiRequests.put('/v1/promotion-setting/' + id + '/published/', null, {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function unPublishPromotion ({ id }) {
  return apiRequests.put('/v1/promotion-setting/' + id + '/unpublished/', null, {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function createPromotion (data) {
  return apiRequests.post('/v1/promotion-setting/', data, {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function updatePromotion (data) {
  return apiRequests.patch('/v1/promotion-setting/' + data.id + '/', data, {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function updatePromotionBudget ({ id, budget, budgetThreshold }) {
  return apiRequests.patch('/v1/promotion-setting/' + id + '/budget/', { budget, budgetThreshold }, {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function applyPromotion ({ id, amount, passengerIdentifierId, doNotSendPromotionMessage, additionalMessage, staffNote }) {
  return apiRequests.post('/v1/promotion-setting/' + id + '/applied/', { amount, passengerIdentifierId, doNotSendPromotionMessage, additionalMessage, staffNote }, {
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function getPromotionTemplate () {
  return {
    fileName: '',
    customName: '',
    metadata: null,
    draftMetadata: {
      timezone: null,
      countryId: 'GHA'
    },
    budget: 0,
    budgetThreshold: null
  }
}

export {
  getPromotion,
  findPromotions,
  findTotalPromotions,
  findValidPromotions,
  findTotalValidPromotions,
  getPromotionScripts,
  archivePromotion,
  unArchivePromotion,
  publishPromotion,
  unPublishPromotion,
  createPromotion,
  updatePromotion,
  getPromotionTemplate,
  updatePromotionBudget,
  applyPromotion
}
